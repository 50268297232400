import * as React from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout/Layout.js";
import useContentful from "../../../utils/useContentful";
import useContentful2 from "../../../utils/useContentful2";
import { Link } from "gatsby";
import Herotitle from "../../../components/proyectos/breca/Herotitle.js";
import Sideimage from "../../../components/proyectos/pais/Sideimage.js";
import Buttonarrow from "../../../components/Botonarrow.js";
import "../../../styles/global.css";
import "../../../styles/breadcrums.css";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import Home from "../../../images/Home/home.png";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Cardcontent = styled.div`
  position: relative;
  padding: 0px 8%;
  h3 {
    color: var(--celeste);
    font-size: 16px;
    line-height: 32px;
  }

  p {
    color: var(--gris);
    width: 100%;
  }
  @media (max-width: 900px) {
    padding: 0px 0px;
    p {
      width: 100%;
      margin-bottom: 20%;
    }
  }
`;

const Botoncontainer = styled.div`
  position: absolute;
  bottom: -24px;
  left: 10%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";

const IndexPage = ({ location }) => {
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });


  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitleBreca[0]}</title>
            <meta
              name={proyectos.fields.metaDescriptionBreca[0]}
              content={proyectos.fields.metaContentBreca[0]}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/breca/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/breca/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/breca/"
            />
          </MetaTags>

          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Innovación Social Breca`,
                    item: `https://www.aporta.org.pe/proyectos/breca/`,
                  },
                ],
              })}
            </script>
          </Helmet>

          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Herotitle
                imagen={
                  proyectos.fields.innovacionSocialPaisSite.fields
                    .headerImagenDeFondo.fields.file.url
                }
                bgcolor={"var(--verdergb)"}
                titulo={proyectos.fields.innovacinSocialBrecaSite.fields.titulo}
                texto={proyectos.fields.innovacinSocialBreca}
              ></Herotitle>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/breca"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social Breca
                </Link>
              </Breadcrumbs>
              <Sideimage
                image={
                  proyectos.fields.innovacinSocialBrecaSite.fields
                    .contencionEmocional.fields.propositoImagen.fields.file.url
                }
                colorb={"#f5f5f5"}
                link={"/proyectos/breca/contencion"}
              >
                <Cardcontent>
                  <h3>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .contencionEmocional.fields.titulo
                    }
                  </h3>

                  <p>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .contencionEmocional.fields.propositoDescripcion
                    }
                  </p>
                </Cardcontent>
                <Botoncontainer>
                  <a href={"/proyectos/breca/contencion"}>
                    <Buttonarrow></Buttonarrow>
                  </a>
                </Botoncontainer>
              </Sideimage>

              <Sideimage
                image={
                  proyectos.fields.innovacinSocialBrecaSite.fields.estudiantes
                    .fields.propositoImagen.fields.file.url
                }
                colorb={"#f5f5f5"}
                link={"/proyectos/breca/estudiantes"}
              >
                <Cardcontent>
                  <h3>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .estudiantes.fields.titulo
                    }
                  </h3>

                  <p>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .estudiantes.fields.propositoDescripcion
                    }
                  </p>
                </Cardcontent>
                <Botoncontainer>
                  <a href={"/proyectos/breca/estudiantes"}>
                    <Buttonarrow></Buttonarrow>
                  </a>
                </Botoncontainer>
              </Sideimage>

              <Sideimage
                image={
                  proyectos.fields.innovacinSocialBrecaSite.fields.desenrollate
                    .fields.propositoImagen.fields.file.url
                }
                colorb={"#f5f5f5"}
                link={"/proyectos/breca/desenrollate"}
              >
                <Cardcontent>
                  <h3>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.titulo
                    }
                  </h3>

                  <p>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields
                        .desenrollate.fields.propositoDescripcion
                    }
                  </p>
                </Cardcontent>
                <Botoncontainer>
                  <a href={"/proyectos/breca/desenrollate"}>
                    <Buttonarrow></Buttonarrow>
                  </a>
                </Botoncontainer>
              </Sideimage>

              <Sideimage
                image={
                  proyectos.fields.innovacinSocialBrecaSite.fields.reco.fields
                    .descripcionProyectoImagen.fields.file.url
                }
                colorb={"#f5f5f5"}
                link={"/proyectos/breca/reco"}
              >
                <Cardcontent>
                  <h3>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields.reco
                        .fields.titulo
                    }
                  </h3>

                  <p>
                    {
                      proyectos.fields.innovacinSocialBrecaSite.fields.reco
                        .fields.propositoDescripcion
                    }
                  </p>
                </Cardcontent>
                <Botoncontainer>
                  <a href={"/proyectos/breca/reco"}>
                    <Buttonarrow></Buttonarrow>
                  </a>
                </Botoncontainer>
              </Sideimage>
              <br />
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
